import React from 'react';
import { graphql, Link } from 'gatsby';

import { Layout, HTML } from '../components';

import '../styles/insights-content.css';

const TableHeaderCell = ({ columnName }, i) => (
    <div key={ `header-${i}` } className='cell items-end row-start-1 bg-secondary text-white'>
        <div className='text-lg lg:text-xl'>
            { columnName }
        </div>
    </div>
);

const TableBodyCell = row => (cell, i) => (
    <div key={ `row-${row}-cell-${i}` } className={ `cell${row % 2 === 1 ? ' bg-gray-200' : ''}` }>
        <div className='lg:text-lg'>
            { cell }
        </div>
    </div>
);

const InsightsContentPageTemplate = ({ data }) => {
    const { title, date, paragraph1, paragraph2, table } = data.markdownRemark.frontmatter;

    const nTableRows = table?.reduce((acc, { columnData }) => columnData?.length > acc ? columnData.length : acc, 0) || 0;

    let tableRows = [  ];

    for (let i = 0; i < nTableRows; i ++) {
        const rowCells = table.reduce((acc, { columnData }) => [ ...acc, columnData[i] === undefined ? null : columnData[i].cell ], [  ]);
        tableRows = [ ...tableRows, ...rowCells.map(TableBodyCell(i)) ];
    }

    return (
        <Layout title={ title }>
            <div className='p-4 lg:px-0 lg:pt-8 lg:pb-20'>
                <div className='w-full lg:w-4/5 mx-auto mb-2 text-3xl lg:text-5xl text-center text-primary font-light'>{ title }</div>
                <div className='w-full mb-4 lg:mb-8 text-center text-gray-500'>{ (new Date(Date.parse(date))).toLocaleString().split(', ').join(" ~ ") }</div>
                <div className='w-full md:w-4/5 lg:w-3/5 mx-auto mb-2 lg:mb-4'>
                    <Link to='/insights' className='text-gray-500 hover:text-black transition-all'>{ "<" } <em className='underline'>Browse all Insights</em></Link>
                </div>
                { paragraph1 && <HTML className='w-full md:w-4/5 lg:w-3/5 mx-auto mb-4 lg:mb-8 text-justify text-lg lg:text-2xl text-primary' html={ paragraph1 }/> }
                <div className='w-full lg:px-4 mb-4 lg:mb-8 flex overflow-x-auto'>
                    <div className='mx-auto grid auto-cols-max'>
                        { table?.map(TableHeaderCell) }
                        { tableRows }
                    </div>
                </div>
                { paragraph2 && <HTML className='w-full md:w-4/5 lg:w-3/5 mx-auto mb-4 lg:mb-8 text-justify text-lg lg:text-2xl text-primary' html={ paragraph2 }/> }
            </div>
        </Layout>
    );
};

export default InsightsContentPageTemplate;

export const pageQuery = graphql`
    query InsightsContentPageTemplateQuery ($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                paragraph1
                paragraph2
                table {
                    columnData {
                        cell
                    }
                    columnName
                }
                date
            }
        }
    }
`;